import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Modal,
  Button,
  Form,
  Container,
  Alert,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import convertTimestamp from "../../utils/convertTimestamp";
import SessionTitle from "./SessionTitle";
import LoadingAnimation from "../compoundOverview/LoadingAnimation";
import SpecificAssistant from "./SpecificAssistant";
import TempChat from "../TempChat/TempChat";

const MyEditorComponent = () => {
  let { currentUser } = useAuth();
  const { timestamp } = useParams();
  const editorRef = useRef(null);
  let [isLoading, setIsLoading] = useState(true);
  let [showModal, setShowModal] = useState(false);
  let [modalText, setModalText] = useState("");
  let [isWaitAnswer, setIsWaitAnswer] = useState(false);
  let [showAlert, setShowAlert] = useState(false);
  let [alertText, setAlertText] = useState("");
  let [isFormDisabled, setIsFormDisabled] = useState(false);
  const uid = currentUser.uid;
  const userEmail = currentUser.email;
  let [documentData, setDocumentData] = useState({});
  let [isEditorVisible, setIsEditorVisible] = useState(true);
  let [isChatVisible, setIsChatVisible] = useState(false);
  const [messages, setMessages] = useState([
    { text: "Test message", sender: "user" },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [label, setLabel] = useState("Chat");
  const [isSensiIconVisible, setIsSensiIconVisible] = useState(true);

  const handleMessageSend = () => {
    if (newMessage.trim() !== "") {
      setMessages([...messages, { text: newMessage, sender: "user" }]);
      setNewMessage("");
      // Here you can implement logic to send the message to your backend or wherever it needs to go
    }
  };

  function handleOnClick() {
    setIsEditorVisible(function (previousValue) {
      return !previousValue;
    });
    setIsChatVisible(function (previousValue) {
      return !previousValue;
    });

    setIsSensiIconVisible(function (previousValue) {
      return !previousValue;
    });
  }

  useEffect(
    function () {
      async function getDocument() {
        try {
          let documents = await axios.get(
            `https://sensi-api.siftlink.com/sessions/${uid}`
          );

          const totalDocuments = documents.data.data.length + 1;
          let document = await axios.get(
            `https://sensi-api.siftlink.com/sessions/session/?uid=${uid}&timestamp=${timestamp}`
          );

          if (document.data.data.length) {
            setDocumentData(document.data.data[0]);
            setIsLoading(false);
          } else {
            let saveNewDoc = axios.post(
              `https://sensi-api.siftlink.com/sessions`,
              JSON.stringify({
                uid,
                timestamp: parseInt(timestamp),
                sessionTitle: "New note " + totalDocuments,
                sessionText: "",
                createdAt: convertTimestamp(Date.now()).toDateAndHour,
                modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            setDocumentData({
              uid,
              timestamp: parseInt(timestamp),
              sessionTitle: "New note " + totalDocuments,
              sessionText: "",
              createdAt: convertTimestamp(Date.now()).toDateAndHour,
              modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
            });
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          showAlertComponent(error.message);
          console.log("Error " + error);
        }
      }

      getDocument();
    },
    [uid, timestamp]
  );
  // useEffect(
  //   function getDocument() {
  //     fetch(
  //       `https://sensi-api.siftlink.com/sessions/session/?uid=${uid}&timestamp=${timestamp}`
  //     )
  //       .then(function getResponse(response) {
  //         if (!response.ok) {
  //           throw new Error(
  //             "An error occur. Response status: " + response.status
  //           );
  //         }
  //         return response.json();
  //       })
  //       .then(function getDocumentData(responseData) {
  //         if (responseData.data.length) {
  //           setDocumentData(responseData.data[0]);
  //           setIsLoading(false);
  //         } else {
  //           fetch(`https://sensi-api.siftlink.com/sessions`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({
  //               uid,
  //               timestamp: parseInt(timestamp),
  //               sessionTitle: "New note " + totalDocuments,
  //               sessionText: "",
  //               createdAt: convertTimestamp(Date.now()).toDateAndHour,
  //               modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
  //             }),
  //           })
  //             .then(function (response) {
  //               if (!response.ok) {
  //                 throw new Error(
  //                   "An error occur. Response status: " + response.status
  //                 );
  //               }
  //               return response.json();
  //             })
  //             .then(function (resposneData) {
  //               if (resposneData.error) {
  //                 showAlertComponent(resposneData.error);
  //                 return;
  //               }
  //             })
  //             .catch(function (res) {
  //               showAlertComponent("An error occur");
  //             });
  //           setDocumentData({
  //             uid,
  //             timestamp: parseInt(timestamp),
  //             sessionTitle: "New note " + totalDocuments,
  //             sessionText: "",
  //             createdAt: convertTimestamp(Date.now()).toDateAndHour,
  //             modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
  //           });
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch(function (error) {
  //         showAlertComponent(error.message);
  //         setIsLoading(false);
  //       });
  //   },
  //   [timestamp, uid, totalDocuments]
  // );

  const tinycomments_create = (req, done, fail) => {
    const { content, createdAt } = req;
    const currentHighlightedText = editorRef.current.selection.getContent({
      format: "text",
    });
    // const editor = editorRef.current;
    // const selectedText = editor.selection.getContent({ format: "text" });

    fetch(`https://sensi-api.siftlink.com/comments/`, {
      method: "POST",
      body: JSON.stringify({
        uid,
        timestamp,
        content,
        createdAt,
        currentHighlightedText,
        userEmail,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to create comment");
        }
        return response.json();
      })
      .then((req2) => {
        const conversationUid = req2.id;
        done({ conversationUid });
      })
      .catch((e) => {
        fail(e);
      });
  };

  const tinycomments_reply = (req, done, fail) => {
    const { conversationUid, content, createdAt } = req;
    const currentHighlightedText = editorRef.current.selection.getContent({
      format: "text",
    });

    fetch(`https://sensi-api.siftlink.com/comments/${conversationUid}`, {
      method: "POST",
      body: JSON.stringify({
        content,
        createdAt,
        currentHighlightedText,
        userEmail,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to reply to comment");
        }
        return response.json();
      })
      .then((req2) => {
        const commentUid = req2.commentUid;
        done({ commentUid });
      })
      .catch((e) => {
        fail(e);
      });
  };

  const tinycomments_edit_comment = (req, done, fail) => {
    const { commentUid, content, modifiedAt } = req;

    fetch(`https://sensi-api.siftlink.com/comments/${commentUid}`, {
      method: "PUT",
      body: JSON.stringify({ content: content, modifiedAt: modifiedAt }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to edit comment");
        }
        return response.json();
      })
      .then((req2) => {
        const canEdit = req2.canEdit;
        done({ canEdit });
      })
      .catch((e) => {
        fail(e);
      });
  };

  const tinycomments_delete_comment = (req, done, fail) => {
    const { conversationUid, commentUid } = req;

    fetch(
      `https://sensi-api.siftlink.com/comments/${conversationUid}/${commentUid}`,
      {
        method: "DELETE",
      }
    ).then((response) => {
      if (response.ok) {
        done({ canDelete: true });
      } else if (response.status === 403) {
        done({ canDelete: false });
      } else {
        fail(new Error("Something has gone wrong..."));
      }
    });
  };

  const tinycomments_delete = (req, done, fail) => {
    const conversationUid = req.conversationUid;
    fetch(`https://sensi-api.siftlink.com/comments/${conversationUid}`, {
      method: "DELETE",
    }).then((response) => {
      if (response.ok) {
        done({ canDelete: true });
      } else if (response.status === 403) {
        done({ canDelete: false });
      } else {
        fail(new Error("Something has gone wrong..."));
      }
    });
  };

  const tinycomments_delete_all = (_req, done, fail) => {
    fetch(`https://sensi-api.siftlink.com/comments`, {
      method: "DELETE",
      body: JSON.stringify({ uid, timestamp }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        done({ canDelete: true });
      } else if (response.status === 403) {
        done({ canDelete: false });
      } else {
        fail(new Error("Something has gone wrong..."));
      }
    });
  };

  const tinycomments_lookup = ({ conversationUid }, done, fail) => {
    fetch(`https://sensi-api.siftlink.com/comments/${conversationUid}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to create comment");
        }
        return response.json();
      })
      .then((responseData) => {
        done(responseData);
      })
      .catch((e) => {
        fail(e);
      });
  };

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleSubmitModal(e) {
    e.preventDefault();
    if (!modalText) {
      showAlertComponent("Please fill input");
      return;
    }

    if (!isWaitAnswer) {
      setIsWaitAnswer(true);
      setIsFormDisabled(true);
      const editor = editorRef.current;

      if (!editor) {
        showAlertComponent("An error occur. No editor found");
        return;
      }

      const selectedText = editor.selection.getContent({ format: "text" });
      const bookmark = editor.selection.getBookmark(2, true);

      // const caretPosition = editor.selection.getRng().startOffset;
      // console.log("caret");
      // console.log(caretPosition);

      axios
        .post(
          `https://sensi-api.siftlink.com/editor/manipulate-content/`,
          {
            highlightedText: selectedText,
            userQuestion: modalText,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            timeout: 300000, // Timeout in milliseconds
          }
        )
        .then((response) => {
          setIsWaitAnswer(false);
          setIsFormDisabled(false);
          setShowModal(false);
          setModalText("");
          if (!response.status === 200) {
            throw new Error(
              "An error occurred. Response status: " + response.status
            );
          }
          return response.data;
        })
        .then((req2) => {
          editor.selection.moveToBookmark(bookmark);

          if (req2.error) {
            showAlertComponent(req2.error);
            return;
          }

          if (req2.classification === "replacement") {
            editor.selection.setContent(`${req2.answer}`);
          } else {
            editor.selection.setContent(selectedText + `${req2.answer}`);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            showAlertComponent("Request timed out");
          } else {
            showAlertComponent("An error occurred: " + error.message);
          }
          setIsWaitAnswer(false);
          setIsFormDisabled(false);
        });
    }

    // fetch(`https://sensi-api.siftlink.com/editor/manipulate-content/`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     highlightedText: selectedText,
    //     userQuestion: modalText,
    //   }),
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     setIsWaitAnswer(false);
    //     setShowModal(false);
    //     setModalText("");
    //     if (!response.ok) {
    //       throw new Error(
    //         "An error occur. Response status: " + response.status
    //       );
    //     }
    //     return response.json();
    //   })
    //   .then((req2) => {
    //     console.log("classification + answer");
    //     console.log(req2);
    //     if (req2.error) {
    //       showAlertComponent(req2.error);
    //       return;
    //     }

    //     if (req2.classification === "replacement") {
    //       editor.selection.setContent(`${req2.answer}`);
    //     } else {
    //       editor.selection.setContent(selectedText + `${req2.answer}`);
    //     }
    //   })
    //   .catch((e) => {
    //     showAlertComponent("An error occur: " + e);
    //     setIsWaitAnswer(false);
    //   });
  }

  function showAlertComponent(alertMessage) {
    setAlertText(alertMessage);
    setShowAlert(true);
    setShowModal(false);
    // setTimeout(function closeAlert() {
    //   setShowAlert(false);
    // }, 5000);
  }

  const handleEditorChange = (content, editor) => {
    editorRef.current = editor;
  };

  function handleCustomButtonClick() {
    setShowModal(true);
  }

  function handleReferncizerButtonClick() {
    const editor = editorRef.current;

    if (!editor) {
      showAlertComponent("An error occur. No editor found");
      return;
    }

    const selectedText = editor.selection.getContent({ format: "text" });
    const bookmark = editor.selection.getBookmark(2, true);

    if (!selectedText) {
      showAlertComponent("Please highlight a text.");
      return;
    }

    axios
      .post(
        `https://sensi-api.siftlink.com/editor/references/`,
        {
          highlightedText: selectedText,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          timeout: 300000, // Timeout in milliseconds
        }
      )
      .then((response) => {
        setIsWaitAnswer(false);
        if (!response.status === 200) {
          throw new Error(
            "An error occurred. Response status: " + response.status
          );
        }
        return response.data;
      })
      .then((req2) => {
        if (req2.error) {
          showAlertComponent(req2.error);
          return;
        }
        editor.selection.moveToBookmark(bookmark);
        editor.selection.setContent(`${req2.references}`);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          showAlertComponent("Request timed out");
        } else {
          showAlertComponent("An error occurred: " + error.message);
        }
        setIsWaitAnswer(false);
      });
  }

  function handleOnEditorChange(editorValue, editor) {
    setDocumentData({
      ...documentData,
      sessionText: editorValue,
      modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
    });
    fetch(`https://sensi-api.siftlink.com/sessions`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid,
        timestamp: parseInt(timestamp),
        sessionText: editorValue,
        modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
      }),
    })
      .then(function getResponse(response) {
        if (!response.ok) {
          throw new Error(
            "An error occur. Response status: " + response.status
          );
        }
        return response.json();
      })
      .then(function (data) {})
      .catch(function (error) {
        showAlertComponent(error.message);
      });
  }

  // async function handleCreateAssistant(event) {
  //   // event.preventDefault();
  //   setIsWaitAnswer(true);
  //   try {
  //     await axios.post(
  //       `https://sensi-api.siftlink.com/editor/create-assistant`,
  //       JSON.stringify({
  //         document: documentData.sessionText,
  //         userId: uid,
  //         documentId: timestamp,
  //       }),
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setIsWaitAnswer(false);
  //   } catch (error) {
  //     setIsWaitAnswer(false);
  //     setAlertText(`Error creating assistant: ${error}`);
  //     console.log("Error");
  //     console.log(error);
  //   }
  // }

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <Container>
        <Alert
          variant="danger"
          show={showAlert}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <p className="text-center">{alertText}</p>
        </Alert>
      </Container>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ marginTop: "7rem" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1rem" }}>
            What can I do for you?
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitModal}>
          <Modal.Body>
            <Form.Control
              // as="textarea"
              disabled={isFormDisabled}
              autoFocus
              type="text"
              placeholder="Enter text"
              value={modalText}
              onChange={(e) => setModalText(e.target.value)}
            />
          </Modal.Body>
        </Form>
        <Modal.Footer>
          {isWaitAnswer ? (
            <Button
              style={{
                backgroundColor: "#C8102E",
                borderColor: "#C8102E",
                width: "60px",
              }}
              disabled
            >
              {" "}
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "white",
                borderColor: "white",
                width: "60px",
              }}
              onClick={handleSubmitModal}
            >
              {/* <i class="bi bi-chevron-double-right"></i> */}
              {/* <i
                class="bi bi-send-fill"
                style={{ transform: "rotate(45deg)" }}
              ></i> */}
              <i
                class="bi bi-arrow-right-square-fill"
                style={{ color: "rgb(200, 16, 46)", fontSize: "2.7rem" }}
              ></i>
              {/* <span> </span>
              <i class="bi bi-arrow-right-square"></i> */}
            </Button>
          )}
          {/* <Button
            size="sm"
            style={{ backgroundColor: "#C8102E", borderColor: "#C8102E" }}
            onClick={handleSubmitModal}
          >
            {isWaitAnswer ? (
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : (
              "Ask"
            )}
          </Button> */}
        </Modal.Footer>
      </Modal>
      <Container fluid style={{ height: "100vh", overflow: "hidden" }}>
        {/* <Button
          style={{ position: "absolute" }}
          onClick={handleCreateAssistant}
        >
          create assistant
        </Button> */}
        {/* <Button
          style={{ position: "absolute" }}
          size="sm"
          onClick={handleCreateAssistant}
        >
          {isWaitAnswer ? (
            <span>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </span>
          ) : (
            "Create assistant"
          )}
        </Button> */}
        {/* <div style={{ height: "5%", minHeight: "50px" }}>
          <SessionTitle session={documentData} />
        </div> */}
        <div
          style={{
            position: "absolute",
            bottom: "130px",
            right: "18px",
            zIndex: "100",
          }}
        >
          <Button
            className="d-xl-none"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            onClick={handleOnClick}
          >
            {isSensiIconVisible ? (
              <img
                style={{ width: "60px", height: "60px" }}
                src="/static/img/sensibot-round.png"
                alt="Sensi"
              />
            ) : (
              <i
                className="bi bi-file-text"
                style={{ color: "black", fontSize: "3rem" }}
              ></i>
            )}
            {/* <img
              style={{ width: "60px", height: "60px" }}
              src="/static/img/sensibot-round.png"
              alt="Sensi"
            />
            <div>
              <i
                className="bi bi-file-text"
                style={{ color: "black", fontSize: "3rem" }}
              ></i>
            </div> */}
          </Button>
        </div>
        <Row style={{ height: "100%" }}>
          <Col
            className={`${isEditorVisible ? "d-block" : "d-none"} d-xl-block`}
            style={{ padding: "0" }}
            xs={12}
            xl={8}
            xxl={8}
          >
            <SessionTitle session={documentData} />
            <div
              style={{
                border: "1px solid #dfdddd",
                borderRight: "0",
                height: "95vh",
                // borderRadius: "15px",
                // boxShadow: label === "Editor" ? "8px 0px 9px 0px gray" : "",
              }}
            >
              <Editor
                apiKey="17gl4ov1yjqkji4ojh0c1yf7cwbqz9uxiqhfctpth1siojp7"
                value={documentData.sessionText}
                onEditorChange={handleOnEditorChange}
                init={{
                  height: "100%",
                  skin: "fabric",
                  icons: "thin",
                  content_css: "fabric",
                  toolbar_mode: "floating",
                  statusbar: false,
                  formats: {
                    hilitecolor: {
                      inline: "span",
                      classes: "hilitecolor",
                      styles: { backgroundColor: "yellow" },
                    },
                  },
                  // sidebar_show: "showcomments",
                  content_style: `body { border-radius: 15px; max-width: 1200px; margin: 2rem auto; page-break-after: always; page-break-inside: avoid; font-size:16px; line-height: 2.5rem; font-family: Helvetica,Arial,sans-serif; } a:hover { cursor: pointer; }
              .blink {animation: blinker 2.5s linear 1;} @keyframes blinker { 0%{background-color: #ecf9ec; opacity: 0.5} 50% { background-color: #ecf9ec; opacity: 0.5 } 100% {background-color: white;} }`,
                  mobile: {
                    content_css: "fabric",
                    content_style: `body { border-radius: 15px; margin: 0.5rem 2%; page-break-after: always; page-break-inside: avoid; font-size:16px; line-height: 2.5rem; font-family: Helvetica,Arial,sans-serif; padding: 1rem; }`,
                    // toolbar: false,
                    menubar: "file edit view insert format tc",
                    menu: {
                      tc: {
                        title: "Assistant",
                        items: "addcomment showcomments deleteallconversations",
                      },
                    },
                    sidebar_show: "",
                  },
                  plugins: [
                    "tinycomments",
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "autolink",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  menubar: "file edit view insert format tc",
                  menu: {
                    tc: {
                      title: "Assistant",
                      items: "addcomment showcomments deleteallconversations",
                    },
                  },
                  removed_menuitems: "newdocument",
                  toolbar:
                    "undo redo | fontsizeinput fontfamily | blocks |" +
                    "createAssistant | bold italic forecolor backcolor| referencizerButton | customButton | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | addcomment showcomments",
                  link_context_toolbar: false,
                  tinycomments_create,
                  tinycomments_reply,
                  tinycomments_edit_comment,
                  tinycomments_delete,
                  tinycomments_delete_all,
                  tinycomments_delete_comment,
                  tinycomments_lookup,
                  setup: (editor) => {
                    editor.on("init", () =>
                      handleEditorChange(editor.getContent(), editor)
                    );
                    // editor.ui.registry.addButton("createAssistant", {
                    //   text: "Create assistant",
                    //   onAction: handleCreateAssistant,
                    // });
                  },
                }}
              />
            </div>
          </Col>
          <Col
            className={`${
              isChatVisible ? "d-block" : "d-none"
            } d-xl-block h-100`}
            xs={12}
            xl={4}
            xxl={4}
            style={{
              border: "1px solid #dfdddd",
              // boxShadow: label === "Chat" ? "-7px 0px 9px 0px gray" : "",
              // borderRadius: "15px",
              padding: "0",
              // backgroundColor: "#edebe9",
              height: "100vh",
            }}
          >
            <TempChat
              editorRef={editorRef}
              showAlertComponent={showAlertComponent}
              uid={currentUser.uid}
              timestamp={timestamp}
              label={label}
              setLabel={setLabel}
              documentData={documentData}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyEditorComponent;
